<template>
  <section id="year">

    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-3">
        <h5 class="py-3 color-gray">{{ title_component.subtitle }}</h5>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-lg-3 mb-3">
        <v-select 
          class="selvue-custom"
          :options="indicadores_list" 
          v-model="indicadores_selected"
          :clearable="false">
        </v-select>
      </div>
      <div class="col-12 col-lg-6 offset-lg-3 mb-3">
        <div class="row">
          <div class="col-12 col-lg-4 col-xl-6 d-flex align-items-center justify-content-lg-end text-secondary">
            <font-awesome-icon 
                icon="filter" 
                class="color-secondary pe-1"
              />
              Filtrar
          </div>
          <div class="col-12 col-lg-8 col-xl-6">
            <v-select 
              class="selvue-custom"
              :options="year_list" 
              v-model="year_selected"
              :clearable="false">
            </v-select>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h5 class="font-title-bold color-gray">Reconocimientos totales</h5>
        <div class="card card-shadow border-round-10 border-0 mb-3" :key="chart_one">
          <div class="card-body">
            <apexchart 
              height="100%" 
              type="line" 
              :options="chartOptions" 
              :series="series"/>
          </div>
        </div>
        <h5 class="font-title-bold color-gray">Total colaboradores activos</h5>
        <div class="card card-shadow border-round-10 border-0" :key="chart_tw">
          <div class="card-body">
            <apexchart 
              height="100%" 
              type="line" 
              :options="chartOptions" 
              :series="series_colab"/>
          </div>
        </div>
      </div>
    </div>

<!--     <div class="year">
      <div class="year-top">
        <div class="year-top-select">
          <div class="select-container" :class="open_indicadores_select_list && 'active'">
            <div class="select-btn" @click="openSelectIndicadores">
              <div class="item">
                {{ indicadores_selected }}
              </div>
              <div class="icon">
                <font-awesome-icon :icon="open_indicadores_select_list ? 'angle-up' : 'angle-down'">
                </font-awesome-icon>
              </div>
            </div>
            <div class="select-list" v-if="open_indicadores_select_list">
              <div v-for="(indi, k) in indicadores_list" :key="k" class="list-item" @click="selectIndicador(indi)">
                {{ indi.label }}
              </div>
            </div>
          </div>
        </div>
        <div class="year-top-filter">
          <div class="title">Filtrar por período:</div>
          <div class="select-container" :class="open_select_list && 'active'">
            <div class="select-btn" @click="openSelectYear">
              <div class="item">
                {{ year_selected }}
              </div>
              <div class="icon">
                <font-awesome-icon :icon="open_select_list ? 'angle-up' : 'angle-down'">
                </font-awesome-icon>
              </div>
            </div>
            <div class="select-list" v-if="open_select_list">
              <div v-for="(year, i) in year_list" :key="i" class="list-item" @click="selectYear(year)">
                {{ year }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="year-container">
        <div class="reconocimientos">
          <div class="content-title">Reconocimientos totales:</div>
          <div class="chart" :key="chart_one">
            <apexchart width="100%" height="100%" type="line" :options="chartOptions" :series="series"></apexchart>
          </div>
          <div class="content-title actives">Total colaboradores activos:</div>
          <div class="chart" :key="chart_tw">
            <apexchart width="100%" height="100%" type="line" :options="chartOptions" :series="series_colab"></apexchart>
          </div>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>
//import SectionTitle from "../Section/SectionTitle.vue";
import {
  GET_INDICADORES_RECONOCIMIENTOS_ANUALES,
  GET_INDICADORES_RECONOCEDORES_ANUALES,
} from "@/apollo/queries";
import SectionTabs from "../Section/SectionTabs.vue";

export default {
  components: {
    //SectionTitle,
    SectionTabs,
  },
  data() {
    return {
      tab_component: [
        {
          name: "Dashboard",
          route: "recognize-Dashboard",
        },
        {
          name: "Indicadores",
          route: "recognize-year",
        },
      ],
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      title_component: {
        title: "Reconoce",
        subtitle: "¡Conoce el impacto de Reconoce! Podrás revisar cifras anuales, por gerencia, top 20, lugar de trabajo y colaborador.",
      },
      indicadores_list: [
        { label: "Año", route: "recognize-year" },
        { label: "Gerencia", route: "recognize-management" },
        { label: "Planta", route: "recognize-age" },
        { label: "Top 20", route: "recognize-ranking" },
        { label: "Consultas por colaborador", route: "recognize-contributor" },
        { label: "Detalles de reconocimiento", route: "recognize-reports" },
      ],
      indicadores_selected: "Año",
      open_indicadores_select_list: false,

      year_list: ["2022","2023", "2024", "2025"],
      year_selected: "2022",
      open_select_list: false,

      chartOptions: {
        colors: [ "#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6"],
        chart: {
          id: "vuechart",
          type: "area",
          toolbar: {
          show: false,
          },   
        },
        xaxis: {
          type: "category",
          categories: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic",
          ],
          tooltip: {
            enabled: false,
          },
          labels: {
            style: {
              fontSize: "16px",
              fontFamily: "Product Sans Regular, Arial, sans-serif",
              fontWeight: "bold",
              colors: [
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
              ],
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#90A4AE",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -5,
          background: {
            enabled: true,
            borderRadius: "50%",
            borderWidth: 1,
            opacity: 0,
            foreColor: "#757679",
          },
          style: {
            fontSize: "16px",
            fontFamily: "Product Sans Regular, Arial, sans-serif",
            fontWeight: "bold",
            colors: [
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
            ],
          },
        },
        stroke: {
          curve: "straight",
          color: "#39DAD6",
          width: 3,
        },
        yaxis: {
          show: false,
        },
        markers: {
          size: 5,
          colors: [ "#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6"],
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ],
      },
      series: [
        {
          name: "years",
          data: [],
        },
      ],
      series_colab: [
        {
          name: "years",
          data: [],
        },
      ],
      chart_one: 0,
      chart_tw: 5,
    };
  },
  mounted() {
    this.getReconocimientos(this.id_empresa);
    this.getReconocedores(this.id_empresa);
  },
  watch: {
    year_selected() {
      this.getReconocimientos(this.id_empresa);
      this.getReconocedores(this.id_empresa);
    },

    indicadores_selected(value) {
      this.$router.push({ name: value.route });
    }
  },
  methods: {
    // Los reconocimientos por mes del año seleccionado
    getReconocimientos(id) {
      this.$apollo
        .query({
          query: GET_INDICADORES_RECONOCIMIENTOS_ANUALES,
          variables: {
            id_empresa: id,
            year: this.year_selected,
          },
        })
        .then((res) => {
          var resp = res.data.IndicadoresReconocimientosAnuales;
          //console.log("year getReconocimientos", resp);
          this.loadReconocimientos(resp);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // Funcion que ordena los reconocimientos para enviarlos al grafico
    loadReconocimientos(r) {
      var arr = [];
      for (var i = 0; i < r.length; i++) {
        var num = Number(r[i].cantidad);
        if (isNaN(num)) {
          arr.push(0);
        } else {
          arr.push(num);
        }
      }
      //console.log(arr);
      this.series[0].data = arr;
      this.chart_one = 1 + this.chart_one;
    },

    // Los recedores por mes del año seleccionado
    getReconocedores(id) {
      this.$apollo
        .query({
          query: GET_INDICADORES_RECONOCEDORES_ANUALES,
          variables: {
            id_empresa: id,
            year: this.year_selected,
          },
        })
        .then((res) => {
          var resp = res.data.IndicadoresReconocedoresAnuales;
          //console.log("year getReconocimientos", resp);
          this.loadRecedores(resp);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // Funcion que ordena los recedores para enviarlos al grafico
    loadRecedores(r) {
      var arr = [];
      for (var i = 0; i < r.length; i++) {
        var num = Number(r[i].cantidad);
        if (isNaN(num)) {
          arr.push(0);
        } else {
          arr.push(num);
        }
      }
      //console.log(arr);
      this.series_colab[0].data = arr;
      this.chart_tw = 1 + this.chart_tw;
    },

    openSelectYear() {
      this.open_select_list = !this.open_select_list;
    },
    selectYear(year) {
      this.year_selected = year;
      this.open_select_list = false;
    },

    openSelectIndicadores() {
      this.open_indicadores_select_list = !this.open_indicadores_select_list;
    },
    selectIndicador(indi) {
      if (this.indicadores_selected != indi.name) {
        this.$router.push({ name: indi.route });
        this.indicadores_selected = indi.name;
      }
      this.open_indicadores_select_list = false;
    },
  },
};
</script>
